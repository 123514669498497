import React from "react"
import { Link } from "gatsby"
import SEO from "../components/shared/seo"

const AboutPage = () => (
  <React.Fragment>
    <SEO title="About" />
    <h2>About Ben</h2>

    <div className="bullet-wrapper">
      <p className="bullet-copy">
        Hello! My name is Ben Chachaj (pronounced "ha-hai"), and I am an
        American developer currently residing in the tri-state area.
      </p>
      <div className="">
        <span className="about-link">
          <a href="mailto:ben.chachaj@gmail.com">Email&nbsp;</a>
        </span>
        |&nbsp;
        <span className="about-link">
          <a href="http://linkedin.com/in/benchachaj">LinkedIn&nbsp;</a>
        </span>
        |&nbsp;
        <span className="about-link">
          <a href="http://github.com/bchachaj">Github&nbsp;</a>
        </span>
      </div>
      <br />
      <h4>Professionally:</h4>
      <p>
        I <span className="accent-muted">develop web applications</span>,
        specializing in user interfaces. I always aspire to be a
        glass-half-full-stack developer, positivity is a power.
      </p>
      <p className="bullet-copy">
        Whether it's crafting code or crafting a sandwhich, I try to{" "}
        <span className="accent-muted">optimize for the best experience</span>{" "}
        all around.
      </p>
      <p className="bullet-copy">
        Right now, I'm especially keen on{" "}
        <span className="accent-muted">
          expanding my domain knowledge of performant web applications
        </span>
        . However, anything related to software engineering is of interest to
        me.{" "}
      </p>
      <p className="bullet-copy">
        Both personally and professionally, I enjoy broadening my horizons.
        Efforts towards this may include traveling, learning, food, professional
        development, meeting new people, and I strive to be grateful for being
        able to experience everything in this world and not take life for
        granted.{" "}
      </p>
      <h6 className="bullet-copy" style={{ color: "white" }}>
        Fairfield University <span className="accent-color">&</span> App Academy
        Alum
      </h6>
    </div>
    <br />
    <br />
    <Link to="/">Go back to the homepage</Link>
    <br />
    <br />
  </React.Fragment>
)

export default AboutPage
